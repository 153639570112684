import { Routes } from '@angular/router';
import { EventsOverviewComponent } from './events-overview/events-overview.component';

export const eventsRoutes: Routes = [
  {
    path: '',
    component: EventsOverviewComponent,
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./events-settings/events-settings.component').then(
        (m) => m.EventsSettingsComponent,
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
