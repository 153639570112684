import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import {
  PreloadAllModules,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
  withViewTransitions,
} from '@angular/router';

import { routes } from './app.routes';
import { environment } from '../environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { firebaseFunctionInterceptor } from './core/interceptors/firebase-function.interceptor';
import { loadingInterceptor } from './core/interceptors/loading.interceptor';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { provideStore, Store } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { LoadingState } from './core/store/loading/loading.state';
import { OrganizationState } from './core/store/organization/organization.state';
import { UserState } from './core/store/user/user.state';
import { errorInterceptor } from './core/interceptors/error.interceptor';
import { appInitialize } from './initialize-app';
import { EventsState } from './core/store/events/events.state';
import { MembersState } from './core/store/members/members.state';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(NgxsReduxDevtoolsPluginModule.forRoot()),
    provideStore(
      [LoadingState, UserState, OrganizationState, EventsState, MembersState],
      {
        developmentMode: !environment.production,
      },
    ),
    provideRouter(
      routes,
      withViewTransitions(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withPreloading(PreloadAllModules),
    ),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideAnimations(),
    provideToastr(),
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        firebaseFunctionInterceptor,
        loadingInterceptor,
        errorInterceptor,
      ]),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitialize,
      multi: true,
      deps: [Auth, Store],
    },
  ],
};
