import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SvgIconComponent } from '../../../../../../shared/components/svg-icon/svg-icon.component';

@Component({
  selector: 'sfa-events',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, SvgIconComponent, RouterOutlet],
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsComponent {}
