import { Action, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { MembersStateModel } from './members.model';
import { StartFirebaseMembersStream, UpdateMembers } from './members.action';
import { MembersService } from '../../services/members.service';

@State<MembersStateModel>({
  name: 'membersState',
  defaults: {
    loading: false,
    members: null,
  },
})
@Injectable()
export class MembersState {
  private membersService = inject(MembersService);

  private members$: Subscription | null = null;

  /**
   * This starts a live fetch from firebase for all members in the active organization.
   */
  @Action(StartFirebaseMembersStream)
  startFirebaseMembersStream(ctx: StateContext<MembersStateModel>) {
    if (this.members$) {
      this.members$.unsubscribe();
    }

    this.members$ = this.membersService
      .getMembers()
      .pipe(tap(() => ctx.patchState({ loading: true })))
      .subscribe({
        next: (members) => ctx.dispatch(new UpdateMembers(members)),
        error: () => ctx.patchState({ loading: false }),
      });
  }

  /**
   * This needs to be a separate action being completed, because otherwise, the state will not update in the dev tools
   */
  @Action(UpdateMembers)
  updateMembers(ctx: StateContext<MembersStateModel>, action: UpdateMembers) {
    ctx.patchState({ loading: false, members: action.members });
  }
}
