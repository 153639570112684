import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { OrganizationState } from '../store/organization/organization.state';

export function errorInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const toastr = inject(ToastrService);
  const store = inject(Store);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const message = '';
      switch (error?.error?.error?.message) {
        case 'organization/admin-role-needed':
          toastr.error(
            `Fehler! Du bist nicht Admin des Vereins "${
              store.selectSnapshot(OrganizationState.activeOrganization).name
            }"`,
          );
          break;
        case 'user/email-not-verified':
          toastr.error('Fehler! Bitte bestätige zuerst deine E-Mail Adresse');
          break;
        default:
          toastr.error('Fehler! Bitte versuche es später erneut');
      }
      console.error(message, error);
      return throwError(() => error);
    }),
  );
}
