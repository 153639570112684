import { Auth } from '@angular/fire/auth';
import { LoadOrganizations } from './core/store/organization/organization.action';
import { Store } from '@ngxs/store';

export function appInitialize(auth: Auth, store: Store) {
  return async (): Promise<any> => {
    await auth.authStateReady(); // The app must wait for the authState
    if (auth.currentUser) {
      store.dispatch(new LoadOrganizations()); // If authenticated also wait for the organizations
    }
  };
}
