@if (user() && environment.app) {
  @if (organizationsLoading()) {
    <sfa-loading loadingClass="smaller" />
  } @else if (organizations()?.length) {
    <mat-form-field class="slim-select without-subscript">
      <mat-select id="organization" [formControl]="selectedOrganization">
        @for (organization of organizations(); track organization) {
          <mat-option [value]="organization">{{
            organization.name
          }}</mat-option>
        }
      </mat-select>
      @if (selectedOrganization.value) {
        <sfa-stripe-product-chip
          [organization]="selectedOrganization.value"
          [sizeSmall]="true"
        />
      }
      <sfu-svg-icon src="expand_more" />
    </mat-form-field>
  }
}
