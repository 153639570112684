<div class="table-actions">
  <div class="row">
    <mat-form-field class="without-subscript small-height">
      <sfu-svg-icon src="search" svgClass="big" />
      <input
        type="text"
        [formControl]="filterControl"
        placeholder="Titel, Jahr, Mitglied"
        aria-label="Filter"
        id="filter"
        matInput
        [matAutocomplete]="auto"
        matAutocompletePosition="above"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="blurInputOnSelectOption()"
      >
        <!-- For some reason @for will throw an error here.. using *ngFor instead -->
        <mat-option
          *ngFor="let option of filteredOptions$()"
          [value]="option | optionValueForMultiInput: filterValue$()"
          >{{ option }}</mat-option
        >
      </mat-autocomplete>
    </mat-form-field>
    @if (filterValue$().length) {
      <sfu-svg-icon
        src="close"
        svgClass="icon-button"
        (click)="filterControl.setValue('')"
      />
    }
  </div>
  <div class="actions-right">
    <sfu-svg-icon
      src="settings"
      svgClass="icon-button big"
      routerLink="settings"
    />
    <sfu-svg-icon
      src="download"
      svgClass="icon-button big"
      (click)="export()"
    />
    <button class="button-primary" (click)="openDialog()">
      Neue Aktivität
    </button>
  </div>
</div>

<span class="bold table-count-text"
  >{{ dataSource.data.length }} von {{ events().length }}</span
>
<div class="table min-height">
  <div class="table-wrapper">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      matSort
      matSortActive="date"
      matSortDirection="desc"
    >
      <!-- name -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
        <td mat-cell *matCellDef="let row">
          {{ row.title }}
        </td>
      </ng-container>

      <!-- memberCount -->
      <ng-container matColumnDef="memberCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mitglieder</th>
        <td mat-cell *matCellDef="let row">
          {{ row.memberCount }}
        </td>
      </ng-container>

      <!-- date -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
        <td mat-cell *matCellDef="let row">
          {{ row.date }}
        </td>
      </ng-container>

      <!-- type -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Art</th>
        <td mat-cell *matCellDef="let row">
          {{ row.type }}
        </td>
      </ng-container>

      <!-- description -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Beschreibung</th>
        <td mat-cell *matCellDef="let row">
          {{ row.description }}
        </td>
      </ng-container>

      <!-- custom fields -->
      @for (customField of customFields$(); track customField.id) {
        <ng-container [matColumnDef]="'custom_' + customField.id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ customField.name }}
          </th>
          <td mat-cell *matCellDef="let row">
            @switch (customField.type) {
              @case (CustomFieldType.BOOLEAN) {
                {{ row["custom_" + customField.id] ? "Ja" : "Nein" }}
              }
              @default {
                {{ row["custom_" + customField.id] }}
              }
            }
          </td>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay$()"></tr>
      <tr
        mat-row
        class="hover-row"
        *matRowDef="let row; columns: columnsToDisplay$()"
        [routerLink]="['/event', row.id]"
      ></tr>
    </table>
  </div>

  @if (environment.features.pagination) {
    <mat-paginator
      [pageSizeOptions]="[10, 25, 50]"
      aria-label="Select page of users"
    ></mat-paginator>
  }
</div>
