import { Routes } from '@angular/router';
import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { organizationGuard } from './core/guards/organizationGuard';
import { userRoleAdminGuard } from './core/guards/user-role-admin.guard';
import { EventsComponent } from './features/events/events.component';
import { eventsRoutes } from './features/events/events.routes';

const redirectUnauthorized = () => redirectUnauthorizedTo(['auth/login']);
const redirectAuthorized = () => redirectLoggedInTo(['account']);

export const routes: Routes = [
  // Some day, the default route will be the dashboard or home component
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full',
  },
  {
    path: 'events',
    component: EventsComponent,
    children: eventsRoutes,
    canActivate: [AuthGuard, organizationGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: 'event/:id',
    loadComponent: () =>
      import('./features/events/event-detail/event-detail.component').then(
        (m) => m.EventDetailComponent,
      ),
    canActivate: [AuthGuard, organizationGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: 'organization/:id',
    loadComponent: () =>
      import(
        './features/account/organizations/organization-detail/organization-detail.component'
      ).then((m) => m.OrganizationDetailComponent),
    canActivate: [AuthGuard, organizationGuard, userRoleAdminGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: 'account',
    loadComponent: () =>
      import('./features/account/account.component').then(
        (m) => m.AccountComponent,
      ),
    loadChildren: () =>
      import('./features/account/account.routes').then((m) => m.accountRoutes),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: 'members',
    loadComponent: () =>
      import('./features/members/members.component').then(
        (m) => m.MembersComponent,
      ),
    loadChildren: () =>
      import('./features/members/members.routes').then((m) => m.membersRoutes),
    canActivate: [AuthGuard, organizationGuard],
    data: { authGuardPipe: redirectUnauthorized },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.routes').then((m) => m.authRoutes),
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectAuthorized },
  },
  {
    path: 'pricing',
    loadComponent: () =>
      import('./features/account/products/products.component').then(
        (m) => m.ProductsComponent,
      ),
  },
  {
    path: 'privacy',
    loadComponent: () =>
      import('./features/legal/privacy/privacy.component').then(
        (m) => m.PrivacyComponent,
      ),
  },
  {
    path: 'imprint',
    loadComponent: () =>
      import('./features/legal/imprint/imprint.component').then(
        (m) => m.ImprintComponent,
      ),
  },
  {
    path: 'agb',
    loadComponent: () =>
      import('./features/legal/agb/agb.component').then((m) => m.AgbComponent),
  },
  { path: '**', redirectTo: '' },
];
