import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  PLATFORM_ID,
  Renderer2,
  signal,
} from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  NavigationSkipped,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AuthService } from './core/services/auth.service';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';
import { environment } from '../environments/environment';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { SvgIconComponent } from '../../../../shared/components/svg-icon/svg-icon.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FeedbackComponent } from './shared/components/feedback/feedback.component';
import { Store } from '@ngxs/store';
import { OrganizationState } from './core/store/organization/organization.state';

@Component({
  selector: 'sfa-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FooterComponent,
    HeaderComponent,
    SidenavComponent,
    LoadingComponent,
    SvgIconComponent,
    FeedbackComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  protected readonly environment = environment;

  private authService = inject(AuthService);
  private renderer = inject(Renderer2);
  private document = inject(DOCUMENT);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private store = inject(Store);

  user = toSignal(this.authService.user);
  showMobileMenu = signal(false);
  activeOrganisationId = toSignal(
    this.store.select(OrganizationState.activeOrganizationId),
  );

  constructor() {
    effect(() => {
      if (this.isBrowser) {
        if (this.showMobileMenu()) {
          setTimeout(
            () => this.renderer.addClass(this.document.body, 'no-scroll'),
            300,
          );
        } else {
          this.renderer.removeClass(this.document.body, 'no-scroll');
        }
      }
    });

    effect(
      () => this.activeOrganisationId() && this.showMobileMenu.set(false),
      { allowSignalWrites: true },
    );

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        // Handle sidenav closing on route change
        (event instanceof NavigationStart ||
          event instanceof NavigationSkipped) &&
          this.showMobileMenu.set(false);
      });
  }
}
