import {
  Component,
  computed,
  effect,
  inject,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { SvgIconComponent } from '../../../../../../../shared/components/svg-icon/svg-icon.component';
import { NewEventDialogComponent } from '../new-event-dialog/new-event-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { SfaEvent } from '../events.models';
import { RouterLink } from '@angular/router';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Member } from '../../members/members.models';
import { environment } from '../../../../environments/environment';
import { OptionValueForMultiInputPipe } from '../../../shared/pipes/option-value-for-multi-input.pipe';
import { DataPrepperService } from '../../../core/services/data-prepper.service';
import { NgForOf } from '@angular/common';
import { ExportService } from '../../../core/services/export.service';
import { CustomFieldType } from '../../../shared/models/models';

@Component({
  selector: 'sfa-events-overview',
  standalone: true,
  imports: [
    SvgIconComponent,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatPaginator,
    MatTable,
    MatSort,
    MatHeaderCellDef,
    MatSortHeader,
    RouterLink,
    FormsModule,
    MatAutocomplete,
    MatFormField,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatInput,
    MatOption,
    OptionValueForMultiInputPipe,
    NgForOf,
  ],
  templateUrl: './events-overview.component.html',
  styleUrl: './events-overview.component.scss',
})
export class EventsOverviewComponent {
  protected readonly environment = environment;
  protected readonly CustomFieldType = CustomFieldType;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  private dialog = inject(Dialog);
  private dataPrepperService = inject(DataPrepperService);
  private exportService = inject(ExportService);

  dataSource: MatTableDataSource<SfaEvent> = new MatTableDataSource<SfaEvent>();
  filterControl = new FormControl('');

  columnsToDisplay$ = this.dataPrepperService.eventColumns$;
  filteredEvents$ = computed(() => [...this.events().filter(this._filter)]);
  events = this.dataPrepperService.eventsWithMembers$;
  members$ = this.dataPrepperService.members$;
  customFields$ = this.dataPrepperService.eventCustomFields$;
  filterValue$ = toSignal(
    this.filterControl.valueChanges,
  ) as WritableSignal<string>;
  filteredOptions$ = computed(() =>
    [
      ...this.filteredEvents$().map((event) => event.title),
      ...this.members$().map(
        (member) => `${member.lastname} ${member.firstname}`,
      ),
    ].filter(
      (option) =>
        option
          .toLowerCase()
          .includes(
            this.filterValue$().split(',').pop()?.trim().toLowerCase() || '',
          ) &&
        !this.filterValue$().toLowerCase().includes(option.toLowerCase()),
    ),
  );

  constructor() {
    effect(() => {
      this.dataSource = new MatTableDataSource(this.filteredEvents$());
      if (environment.features.pagination) {
        this.dataSource.paginator = this.paginator;
      }
      this.dataSource.sort = this.sort;
    });

    this.filterControl.setValue('');
  }

  openDialog(): void {
    this.dialog.open<string>(NewEventDialogComponent, {
      disableClose: true,
      data: undefined,
      autoFocus: false,
    });
  }

  _filter = (event: SfaEvent) => {
    let filterBase = event.title;
    event.members?.forEach(
      (member: Member) =>
        (filterBase += member.lastname + ' ' + member.firstname),
    );
    filterBase += new Date(event.date).getFullYear();

    return this.filterValue$()
      .replace(/\s+/g, '')
      .toLowerCase()
      .split(',')
      .every((criterion: string) =>
        filterBase.replace(/\s+/g, '').toLowerCase().includes(criterion),
      );
  };

  // don't ask why
  blurInputOnSelectOption() {
    setTimeout(() => document.getElementById('filter')?.blur(), 0);
  }

  export() {
    const _toPreparedExcelData = (event: SfaEvent) => {
      // Initial prepared data object
      const preparedData = {
        Titel: event.title,
        Datum: event.date,
        Mitgliederanzahl: event.members?.length,
        Art: event.type,
        Bemerkung: event.description,
      };

      // Dynamically add custom fields to the preparedData object
      this.customFields$()?.forEach(
        (customField) =>
          ((preparedData as any)[customField.name] = (event as any)[
            `custom_${customField.id}`
          ]),
      );

      // Finally add all events
      (preparedData as any)['Mitglieder'] = event.members
        ?.map((member) => `${member.lastname} ${member.firstname}`)
        .join(', ');

      return preparedData;
    };

    const excelPreparedData = this.sortDatasourceByMatSort(
      this.dataSource.filteredData,
    ).map(_toPreparedExcelData);

    this.exportService.exportToExcel(
      excelPreparedData,
      'schriftfuehrer_aktivitäten_exportiert',
      'Aktivitaeten',
    );
  }

  private sortDatasourceByMatSort(data: any[]): any[] {
    if (!this.sort?.active || this.sort.direction === '') return data;
    const { active, direction } = this.sort;
    return data.sort((a, b) => {
      const aValue = a[active] ?? Infinity;
      const bValue = b[active] ?? Infinity;
      return (
        (aValue > bValue ? 1 : aValue < bValue ? -1 : 0) *
        (direction === 'asc' ? 1 : -1)
      );
    });
  }
}
