import { inject, Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { Store } from '@ngxs/store';
import { OrganizationState } from '../store/organization/organization.state';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  private firebaseService = inject(FirebaseService);

  private activeOrganisationId = inject(Store).selectSignal(
    OrganizationState.activeOrganizationId,
  );

  getMembers() {
    if (!this.activeOrganisationId()) {
      return of([]);
    }
    return this.firebaseService.getCollection(
      `organizations/${this.activeOrganisationId()}/members`,
    );
  }
}
