import { Action, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { EventsStateModel } from './events.model';
import { StartFirebaseEventsStream, UpdateEvents } from './events.action';
import { Subscription, tap } from 'rxjs';
import { EventsService } from '../../services/events.service';

@State<EventsStateModel>({
  name: 'eventsState',
  defaults: {
    loading: false,
    events: null,
  },
})
@Injectable()
export class EventsState {
  private eventsService = inject(EventsService);

  private events$: Subscription | null = null;

  /**
   * This starts a live fetch from firebase for all events in the active organization.
   */
  @Action(StartFirebaseEventsStream)
  startFirebaseEventsStream(ctx: StateContext<EventsStateModel>) {
    if (this.events$) {
      this.events$.unsubscribe();
    }

    this.events$ = this.eventsService
      .getEvents()
      .pipe(tap(() => ctx.patchState({ loading: true })))
      .subscribe({
        next: (events) => ctx.dispatch(new UpdateEvents(events)),
        error: () => ctx.patchState({ loading: false }),
      });
  }

  /**
   * This needs to be a separate action being completed, because otherwise, the state will not update in the dev tools
   */
  @Action(UpdateEvents)
  updateEvents(ctx: StateContext<EventsStateModel>, action: UpdateEvents) {
    ctx.patchState({ loading: false, events: action.events });
  }
}
