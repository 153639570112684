<div class="header-row">
  <div class="logo-row row">
    <a class="logo-wrapper" routerLink="/">
      <sfu-svg-icon-static class="logo-svg" [svgClass]="'very-big'" />
      <span
        class="logo-text font-big"
        [class]="!environment.app ? 'landing' : ''"
        >schriftfuehrer.at</span
      >
    </a>
    @if (environment.app) {
      <sfa-organization-select class="header-organization-select" />
    }
  </div>

  @if (!environment.app) {
    @if (environment.features.connectLandingAndApp) {
      <a class="button-primary" [href]="environment.domains.app"
        >Jetzt starten</a
      >
    } @else {
      <a class="button-primary" href="mailto:tobiaszwinger.tz@gmail.com"
        >Ich bin interessiert</a
      >
    }
  } @else if (user$()) {
    <div class="profile button" routerLink="/account">
      <img
        [src]="user$().photoURL"
        (load)="userImageLoaded$.set(true)"
        [ngClass]="{ hidden: !userImageLoaded$() }"
        alt="user image"
        class="user-img"
      />
      @if (!userImageLoaded$()) {
        <sfu-svg-icon
          src="person"
          [badgePurposes]="[
            BadgePurpose.EMAIL_NOT_VERIFIED,
            BadgePurpose.NO_ORGANIZATION,
            BadgePurpose.ORGANIZATION_HAS_USER_REQUESTS
          ]"
        />
      }
      <span class="user-name">{{ user$().displayName || user$().email }}</span>
    </div>
  }
</div>
<hr />
